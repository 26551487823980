/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cognito_identity_pool_id": "ap-northeast-1:a077e25e-2735-4ac4-a687-3b930b52275b",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_0MTOodoal",
    "aws_user_pools_web_client_id": "cn8eke6mf7nql2a6naqbol5uh",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://2pq7efvrefclrmstwihm3gzmoi.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "fanauthgw-admin-prod",
    "aws_user_files_s3_bucket_region": "ap-northeast-1"
};


export default awsmobile;
